// Generated by Framer (abcfa95)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {J5ebIlJul: {hover: true}};

const cycleOrder = ["J5ebIlJul"];

const serializationHash = "framer-iw6OO"

const variantClassNames = {J5ebIlJul: "framer-v-8b5d8h"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation = {opacity: 0.5, rotate: 0, rotateX: 0, rotateY: 0, scale: 0.95, skewX: 0, skewY: 0, transition: transition2}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link2, newTab, socialIcon, width, ...props}) => { return {...props, IA17IhJis: link2 ?? props.IA17IhJis, sFLE7hDGt: socialIcon ?? props.sFLE7hDGt ?? "FacebookLogo", Wr1RIycrN: newTab ?? props.Wr1RIycrN} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link2?: string;newTab?: boolean;socialIcon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, IA17IhJis, Wr1RIycrN, sFLE7hDGt, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "J5ebIlJul", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={IA17IhJis} openInNewTab={Wr1RIycrN}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-8b5d8h", className, classNames)} framer-n8e1a1`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"J5ebIlJul"} ref={ref ?? ref1} style={{opacity: 0.5, ...style}} variants={{"J5ebIlJul-hover": {opacity: 1}}} {...addPropertyOverrides({"J5ebIlJul-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1r19bip-container"} layoutDependency={layoutDependency} layoutId={"v0hnqfLGQ-container"} whileTap={animation}><Phosphor color={"rgb(255, 255, 255)"} height={"100%"} iconSearch={"House"} iconSelection={sFLE7hDGt} id={"v0hnqfLGQ"} layoutId={"v0hnqfLGQ"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-iw6OO.framer-n8e1a1, .framer-iw6OO .framer-n8e1a1 { display: block; }", ".framer-iw6OO.framer-8b5d8h { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-iw6OO .framer-1r19bip-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-iw6OO.framer-8b5d8h { gap: 0px; } .framer-iw6OO.framer-8b5d8h > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-iw6OO.framer-8b5d8h > :first-child { margin-left: 0px; } .framer-iw6OO.framer-8b5d8h > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"GOmX9Ywiq":{"layout":["auto","auto"]}}}
 * @framerVariables {"IA17IhJis":"link2","Wr1RIycrN":"newTab","sFLE7hDGt":"socialIcon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerL7ZdAnlzE: React.ComponentType<Props> = withCSS(Component, css, "framer-iw6OO") as typeof Component;
export default FramerL7ZdAnlzE;

FramerL7ZdAnlzE.displayName = "Social Icon";

FramerL7ZdAnlzE.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerL7ZdAnlzE, {IA17IhJis: {title: "Link 2", type: ControlType.Link}, Wr1RIycrN: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}, sFLE7hDGt: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "FacebookLogo", description: undefined, hidden: undefined, title: "Social Icon"}} as any)

addFonts(FramerL7ZdAnlzE, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})